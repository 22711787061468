.ps-qrcode-tip {
  margin-top: 16px;
  text-align: center;
  color: #666;

  .ps-layout-links {
    font-size: 14px;
  }
}

.ps-modal-footer-button {
  user-select: none;
}
